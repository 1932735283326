<sci-header [ngClass]="{'scrolled' : hasPageScrolled }" class="header" [(sidenavOpen)]="sidenavOpen"></sci-header>

<mat-sidenav-container class="sidenav-container" autosize>
  <mat-sidenav autoFocus="false" mode="over" position="end" fixedInViewport fixedTopGap="72" [opened]="sidenavOpen"
               (closedStart)="sidenavOpen = false">
    <sci-mobile-header-menu *sciBreakpoint="'tablet-'" (sidenavClose)="sidenavOpen = false">
    </sci-mobile-header-menu>
  </mat-sidenav>

  <mat-sidenav-content class="sidenav-content">
    <div [@routeAnimations]="prepareRoute(outlet)" class="content" [ngClass]="prepareClass(outlet)">
      <router-outlet #outlet="outlet" (activate)="onActivate()"></router-outlet>
    </div>
    <sci-footer [acknowledgement]="footerParameters.acknowledgement" [phoneNumber]="footerParameters.phoneNumber"></sci-footer>
  </mat-sidenav-content>
</mat-sidenav-container>
