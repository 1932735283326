<div class="text-column">
  <h3 class="title">{{ book.title }}</h3>
  <div class="author">Author: {{ book.author }}</div>
  <div class="publisher">
    Published by: 
    <a class="link" href="http://mitpress.mit.edu/" rel="noreferrer noopener" target="_blank">
      {{ book.publisher }}
    </a>
  </div>
  <div class="markdown full" markdown [data]="fullContent"></div>
  <div class="markdown partial" markdown [data]="partialContent"></div>
  <div *ngIf="hasLongContent" class="show-more" (click)="fullContentVisible = !fullContentVisible">
    {{ fullContentVisible ? 'Show less' : 'Read more...' }}
  </div>
  <div class="buttons">
    <a *ngIf="book.pdfLink" class="button" target="_blank" rel="noreferrer noopener" [href]="book.pdfLink">
      BOOK EXCERPT (PDF)
    </a>
    <a *ngIf="book.amazonLink" class="button" target="_blank" rel="noreferrer noopener" [href]="book.amazonLink">
      BUY ON AMAZON
    </a>
  </div>
</div>
<div class="carousel-column">
  <sci-carousel class="carousel">
    <sci-carousel-item *ngFor="let image of book.images; index as i">
      <div class="carousel-item">
        <ng-container *sciBreakpoint="'mobile-'; else: desktop">
          <img class="carousel-image" [src]="image.sm" alt="Image #{{ i }} for book {{ book.title }}">
        </ng-container>
        <ng-template #desktop>
          <img class="carousel-image" [src]="image.lg" alt="Image #{{ i }} for book {{ book.title }}">
        </ng-template>
      </div>
    </sci-carousel-item>
  </sci-carousel>
</div>
