<div class="purchase-modal wrapper">
  <div class="container">
    <mat-dialog-content *ngIf="!confirmation" class="screen1">
      <div class="purchase-info">
        <h4 class="purchase-title">Purchase Map(s)</h4>
        <p class="description">
          Most exhibit maps can be purchased individually. Maps are 30" x 24" (76cm x 61cm). Different Pricing options are available.
        </p>
        <ul class="purchase-options">
          <li>Inkjet print: $45 ea*</li>
          <li>Premium archival print: $100 ea*</li>
          <li>Iteration poster sets (inkjet): $150 per iteration* or $950 for all 20 posters*</li>
        </ul>
        <p>*plus shipping</p>
        <p class="instructions">
          Complete and submit the form. You will be contacted by a staff member to complete your order and verify all information.
        </p>
      </div>

      <div class="purchase-form-container">
        <div class="purchase-form">
          <div class="name-inputs">
            <mat-form-field class="first-name" appearance="fill">
              <mat-label >First Name</mat-label>
              <input class="first-name-input" (change)="change($event.target, 'firstName')" placeholder="Enter first name" [formControl]="firstName" matInput>
            </mat-form-field>
            <mat-form-field class="last-name" appearance="fill">
              <mat-label>Last Name</mat-label>
              <input class="last-name-input" (change)="change($event.target, 'lastName')" placeholder="Enter last name" [formControl]="lastName" matInput>
            </mat-form-field>
          </div>
          <div class="shipping-input">
            <mat-form-field class="shipping-address" appearance="fill">
              <mat-label>Shipping Address</mat-label>
              <input class="shipping-address-input" (change)="change($event.target, 'shipAddress')" placeholder="Enter shipping address" [formControl]="address" matInput>
            </mat-form-field>
          </div>
          <div class="address-inputs">
            <mat-form-field class="city" appearance="fill">
              <mat-label>City</mat-label>
              <input class="city-input" (change)="change($event.target, 'city')" placeholder="Enter city" [formControl]="city" matInput>
            </mat-form-field>
            <mat-form-field class="state" appearance="fill">
              <mat-label>State</mat-label>
              <mat-select class="state-input" (selectionChange)="stateChange($event)" [formControl]="state">
                <mat-option *ngFor="let state of states" [value]="state">
                  {{state}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="zip" appearance="fill">
              <mat-label>Zip</mat-label>
              <input class="zip-input" (change)="change($event.target, 'zip')" placeholder="xxxxx" [formControl]="zip" matInput>
            </mat-form-field>
          </div>
          <div class="contact-inputs">
            <mat-form-field class="email" appearance="fill">
              <mat-label>Email</mat-label>
              <input class="email-input" (change)="change($event.target, 'email')" placeholder="Enter email address" [formControl]="email" matInput>
              <mat-error *ngIf="email.invalid">{{getErrorMessage()}}</mat-error>
            </mat-form-field>
            <mat-form-field class="phone" appearance="fill">
              <mat-label>Phone</mat-label>
              <input class="phone-input" (change)="change($event.target, 'phone')" placeholder="xxx-xxx-xxxx" [formControl]="phone" matInput>
            </mat-form-field>
          </div>
          <div class="shipping-info">
            <div class="shipping-info-label">Map name(s) and preferred shipping method</div>
            <textarea (change)="updateShipInfo($event.target)" class="textarea" matInput></textarea>
          </div>
          <div class="button-container">
            <button class="cancel button" (click)="close()">CANCEL</button>
            <a class="send button" [class.disabled]="!allValid()" (click)="buttonClickSend()" href="{{mailLink}}">SEND</a>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-content *ngIf="confirmation" class="screen2">
      <div class="content">
        <h1 class="confirmation-header">
          Thank you for your order!
        </h1>
        <p class="confirmation-text">
          A staff member will contact you to complete the payment process and confirm details.
        </p>
        <button class="button" (click)="close()">DONE</button>
      </div>
    </mat-dialog-content>
  </div>
</div>