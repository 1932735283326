<div class="section-1">
  <a class="email" href="mailto:cnscntr@indiana.edu" target="_blank" rel="noopener noreferrer">
    <mat-icon class="icon" svgIcon="social:email"></mat-icon>
    <span>&nbsp;&nbsp;cnscntr@indiana.edu</span>
  </a>

  <div class="phone">
    <mat-icon class="icon">call</mat-icon>
    <span>&nbsp;&nbsp;812-855-9930</span>
  </div>
</div>

<div *sciBreakpoint="'tablet-'; else: desktop" class="section-2">
  <a class="website" href="https://cns.iu.edu" target="_blank" rel="noopener noreferrer">
    <mat-icon class="icon">language</mat-icon>
    <span>&nbsp;&nbsp;CNS:&nbsp;&nbsp;</span>
    <span class="link">cns.iu.edu</span>
  </a>

  <div class="social">
    <span>Visit us:&nbsp;&nbsp;</span>
    <div class="social-icons">
      <a [href]="twitter" class="link" target="_blank" rel="noopener noreferrer">
        <mat-icon class="icon" svgIcon="social:twitter"></mat-icon>
      </a>
      <a [href]="facebook" class="link" target="_blank" rel="noopener noreferrer">
        <mat-icon class="icon" svgIcon="social:facebook"></mat-icon>
      </a>
      <a [href]="instagram" class="link" target="_blank" rel="noopener noreferrer">
        <mat-icon class="icon" svgIcon="social:instagram"></mat-icon>
      </a>
    </div>
  </div>
</div>

<ng-template #desktop>
  <div class="section-3">
    <div class="website">
      <span>Learn about CNS:&nbsp;&nbsp;</span>
      <a class="link" href="https://cns.iu.edu" target="_blank" rel="noopener noreferrer">cns.iu.edu</a>
    </div>

    <div class="social">
      <span>Visit us on social media:</span>
    </div>
    <div class="social-icons">
      <a [href]="twitter" class="link" target="_blank" rel="noopener noreferrer">
        <mat-icon class="icon" svgIcon="social:twitter"></mat-icon>
      </a>
      <a [href]="facebook" class="link" target="_blank" rel="noopener noreferrer">
        <mat-icon class="icon" svgIcon="social:facebook"></mat-icon>
      </a>
      <a [href]="instagram" class="link" target="_blank" rel="noopener noreferrer">
        <mat-icon class="icon" svgIcon="social:instagram"></mat-icon>
      </a>
    </div>
  </div>
</ng-template>
