<div class="contact">
  <div class="social">
    <a mat-icon-button [href]="twitter" class="button twitter" target="_blank" rel="noopener noreferrer">
      <mat-icon class="icon" svgIcon="social:x"></mat-icon>
    </a>
  </div>

  <div class="social">
    <a mat-icon-button [href]="bluesky" class="button bluesky" target="_blank" rel="noopener noreferrer">
      <mat-icon class="icon" svgIcon="social:bluesky"></mat-icon>
    </a>
  </div>

  <div class="social">
    <a mat-icon-button [href]="facebook" class="button facebook" target="_blank" rel="noopener noreferrer">
      <mat-icon class="icon" svgIcon="social:facebook"></mat-icon>
    </a>
  </div>

  <div class="social">
    <a mat-icon-button [href]="instagram" class="button instagram" target="_blank" rel="noopener noreferrer">
      <mat-icon class="icon" svgIcon="social:instagram"></mat-icon>
    </a>
  </div>

  <div class="social">
    <a mat-icon-button class="button email" href="mailto:cnscntr@iu.edu" target="_blank" rel="noopener noreferrer">
      <mat-icon class="icon font">email</mat-icon>
    </a>
  </div>

  <div class="social">
    <a mat-icon-button class="button map" href="https://goo.gl/maps/yFFVMmMRzBNYU1pq8" target="_blank" rel="noopener noreferrer">
      <mat-icon class="icon font">room</mat-icon>
    </a>
  </div>

  <div class="phone">
    <mat-icon class="icon font">call</mat-icon>
    <span class="text mat-display-2">{{ phoneNumber }}</span>
  </div>
</div>

<div class="acknowledgement mat-small">
  {{ acknowledgement }}
</div>
