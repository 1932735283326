<div class="swiper swiper-container" [swiper]="config">
  <div class="swiper-wrapper">
    <ng-content select="sci-carousel-item"></ng-content>
  </div>

  <div class="swiper-pagination"></div>

  <div class="swiper-button-prev"></div>
  <div class="swiper-button-next"></div>
</div>
