<div class="details-container">
  <div class="back-button" (click)="closed.emit()">
    <mat-icon class="back-icon">chevron_left</mat-icon>
    <div>BACK</div>
  </div>

  <h2 class="makers-title">Makers: {{title}}</h2>

  <div class="maker-container">
    <div class="maker-info" *ngFor="let maker of makers">
      <img class="thumbnail" [src]="maker.image" alt="maker image">
      <div class="info">
        <div class="name">{{maker.name}}</div>
        <div class="job">{{maker.title}}</div>
        <div class="bio">
          <div markdown [data]="maker.body">
          </div>
        </div>
      </div>
    </div>
  </div>

</div>